import styled from '@emotion/styled'
import { FadeInOutAnimation } from 'app/components/Common/FadeInOutAnimation'
import {
  Props as ServicesProps,
  Service,
} from 'app/components/IconsServices/Service'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  title?: string
  services?: ServicesProps[]
}

export const OfferServices = memo(function OfferServices({
  title,
  services,
}: Props) {
  if (!services || services.length < 1) {
    return null
  }

  return (
    <Container>
      <FadeInOutAnimation>
        <Wrapper>
          {title ? <Title>{title}</Title> : null}
          <Services dial={2} row wrap>
            {services.map((item, index) => {
              return <Service key={index} {...item} />
            })}
          </Services>
        </Wrapper>
      </FadeInOutAnimation>
    </Container>
  )
})

const Container = styled.section`
  margin: 9.5625rem 0 5.25rem;
  text-align: center;

  @media (max-width: 1199px) {
    margin: 5.25rem 0;
  }
`

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  max-width: 61.625rem;
  margin: auto;
  padding: 3.75rem 1.5rem 4.375rem;
`

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  line-height: 2rem;
`

const Services = styled(FlexBox)`
  > div {
    width: 20%;
    margin-top: 2.25rem;
    div {
      width: 3rem;
      height: 3rem;
      img {
        height: 1.25rem;
      }
    }
    label {
      margin-top: 0.5rem;
    }
  }

  @media (max-width: 767px) {
    > div {
      width: 33.333%;
    }
  }
`
