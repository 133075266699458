import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { FadeInOutAnimation } from 'app/components/Common/FadeInOutAnimation'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'
import * as Icon from 'react-feather'

export interface Props {
  category?: string
  description?: string
  discount?: string
  languageCode: string
  priceFrom?: string
  requestButtonURL?: string
  title?: string
  validity?: string
}

export const OfferIntro = memo(function OfferIntro({
  category,
  description,
  discount,
  languageCode,
  priceFrom,
  requestButtonURL,
  title,
  validity,
}: Props) {
  const REQUEST_BUTTON_REGEX = new RegExp('^(https?|ftp|www)://')
  const requestButtonParam = requestButtonURL
    ? REQUEST_BUTTON_REGEX.test(requestButtonURL)
      ? requestButtonURL
      : `${requestButtonURL}?offer=${title}`
    : undefined

  return (
    <Container>
      <Wrapper row space="between">
        <TextWrap>
          <FadeInOutAnimation>
            <div>
              {category ? <Category>{category}</Category> : null}
              {title ? <Title>{title}</Title> : null}
              {validity ? (
                <Validity row>
                  <Icon.Tag />
                  {validity}
                </Validity>
              ) : null}
              {description ? (
                <Description
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              ) : null}
            </div>
          </FadeInOutAnimation>
        </TextWrap>
        <Info>
          <FadeInOutAnimation>
            <div>
              {discount ? (
                <Discount>
                  <b>{discount}</b>{' '}
                  {useVocabularyData('discount', languageCode)}
                </Discount>
              ) : null}
              {priceFrom ? (
                <>
                  <PriceFromLabel>
                    {useVocabularyData('starting-from', languageCode)}
                  </PriceFromLabel>
                  <PriceFromValue>{priceFrom}</PriceFromValue>
                </>
              ) : null}
              {requestButtonURL ? (
                <CTA
                  externalURL={requestButtonParam}
                  target="_blank"
                  label={useVocabularyData('request-info', languageCode)}
                />
              ) : null}
            </div>
          </FadeInOutAnimation>
        </Info>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
`

const Wrapper = styled(FlexBox)`
  max-width: 73.5rem;
  margin: auto;
  padding: 0 1.5rem;

  @media (max-width: 767px) {
    display: block;
  }
`

const TextWrap = styled.div`
  width: 60%;
  padding: 5.25rem 3rem 5.25rem 0;

  @media (max-width: 1199px) {
    padding: 3rem 2.25rem 3rem 0;
  }

  @media (max-width: 767px) {
    width: 100%;
    padding: 2.25rem 0 0;
  }
`

const Category = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryDark1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.6875rem;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 0.875rem;
  margin-bottom: 0.75rem;
  text-transform: uppercase;
`

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 1.875rem;
`

const Validity = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.6875rem;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 0.875rem;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  svg {
    height: 16px;
    margin-right: 0.375rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.875rem;
`

const Info = styled.div`
  width: 17.0625rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  padding-top: 3.4375rem;
  text-align: center;

  @media (max-width: 767px) {
    width: 100%;
    margin-top: 2.25rem;
    padding-top: 1.5rem;
  }
`

const Discount = styled.span`
  display: inline-block;
  background: ${({ theme }) => theme.colors.variants.neutralDark2};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.6875rem;
  letter-spacing: 1px;
  line-height: 0.875rem;
  margin-bottom: 0.75rem;
  padding: 0.5rem 0.75rem;
  text-transform: uppercase;
`

const PriceFromLabel = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.6875rem;
  letter-spacing: 1px;
  line-height: 0.875rem;
  text-transform: uppercase;
`

const PriceFromValue = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.625rem;
  line-height: 3.375rem;
`

const CTA = styled(Button)`
  width: 100%;
  justify-content: center;
  margin-top: 4.5rem;
  &:hover {
    background: ${({ theme }) =>
      theme.colors.getHoverColor(`${theme.colors.variants.primaryDark1}`)};
  }

  @media (max-width: 767px) {
    margin-top: 1.5rem;
  }
`
