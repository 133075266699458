import styled from '@emotion/styled'
import { Hero } from 'app/components/Hero'
import { OfferConditions } from 'app/components/OfferConditions'
import { OfferIntro } from 'app/components/OfferIntro'
import { OfferServices } from 'app/components/OfferServices'
import { OffersSlider } from 'app/components/OffersSlider'
import { SEO } from 'app/components/SEO'
import { Footer } from 'app/containers/Footer'
import { GoogleAnalytics } from 'app/containers/GoogleAnalytics'
import { Header, Props as HeaderProps } from 'app/containers/Header'
import { StructuredData } from 'app/containers/StructuredData'
import { PageProps } from 'gatsby'
import React, { memo } from 'react'

import { Props } from './contents'

export interface PageContext {
  id: string
  languageCode: string
  languagePrefix: string | null
  headerProps?: HeaderProps
}

export interface Context extends PageContext {
  props: Props
}

export default memo(function OfferPageTemplate({
  pageContext,
}: PageProps<void, Context>) {
  const context = pageContext as any

  return (
    <Container>
      <SEO {...context.seoProps} />
      <GoogleAnalytics />
      {pageContext.headerProps ? (
        <Header
          pageID={pageContext.id}
          languageCode={pageContext.languageCode}
          languagePrefix={pageContext.languagePrefix}
          {...pageContext.headerProps}
        />
      ) : null}
      {context.heroProps ? (
        <Hero variant="compact" {...context.heroProps} />
      ) : null}
      {context.offerIntroProps ? (
        <OfferIntro {...context.offerIntroProps} />
      ) : null}
      {context.offerConditionsProps ? (
        <OfferConditions {...context.offerConditionsProps} />
      ) : null}
      {context.offerServicesProps ? (
        <OfferServices {...context.offerServicesProps} />
      ) : null}
      {context.offersSliderProps ? (
        <OffersSlider variant="compact" {...context.offersSliderProps} />
      ) : null}
      <Footer
        languageCode={pageContext.languageCode}
        languagePrefix={pageContext.languagePrefix || 'it'}
      />
      <StructuredData languageCode={pageContext.languageCode} />
    </Container>
  )
})

const Container = styled.main``
