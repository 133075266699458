import styled from '@emotion/styled'
import { FadeInOutAnimation } from 'app/components/Common/FadeInOutAnimation'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

export interface Props {
  conditions?: string
  includes?: string
  languageCode: string
}

export const OfferConditions = memo(function OfferConditions({
  conditions,
  includes,
  languageCode,
}: Props) {
  return (
    <Container>
      <Wrapper row space="between" wrap>
        <Includes>
          {includes ? (
            <FadeInOutAnimation>
              <div>
                <Label>
                  {useVocabularyData('offer-includes', languageCode)}
                </Label>
                <Description dangerouslySetInnerHTML={{ __html: includes }} />
              </div>
            </FadeInOutAnimation>
          ) : null}
        </Includes>
        <Conditions>
          {conditions ? (
            <FadeInOutAnimation>
              <div>
                <Label>{useVocabularyData('conditions', languageCode)}</Label>
                <Description dangerouslySetInnerHTML={{ __html: conditions }} />
              </div>
            </FadeInOutAnimation>
          ) : null}
        </Conditions>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 5.25rem 0;
`

const Wrapper = styled(FlexBox)`
  max-width: 73.5rem;
  margin: auto;
  padding: 0 1.5rem;
`

const Includes = styled.div`
  width: calc(100% - 23.75rem);
  margin-right: 6.6875rem;
  h2 {
    display: flex;
    align-items: center;
    flex-direction: row;
    &:after {
      content: '';
      flex: 1 1;
      background: ${({ theme }) => theme.colors.variants.neutralLight1};
      height: 2px;
      margin-left: 1.75rem;
    }
  }

  @media (max-width: 767px) {
    width: 100%;
    margin-right: 0;
  }
`

const Conditions = styled.div`
  width: 17.0625rem;

  @media (max-width: 767px) {
    width: 100%;
    margin-top: 2.25rem;
    h2 {
      display: flex;
      align-items: center;
      flex-direction: row;
      &:after {
        content: '';
        flex: 1 1;
        background: ${({ theme }) => theme.colors.variants.neutralLight1};
        height: 2px;
        margin-left: 1.75rem;
      }
    }
  }
`

const Label = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  font-weight: 600;
  letter-spacing: 1.5px;
  line-height: 1rem;
  margin-bottom: 1.25rem;
  text-transform: uppercase;
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.875rem;
`
